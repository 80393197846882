import { useEffect } from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";



const TopBarLogin = (props) => {

     const [std_image, set_std_image] = useState()
     const [app_logo, set_app_logo] = useState(null)
     const [home_banner, set_home_banner] = useState(null)
     const [home_banner_link, set_home_banner_link] = useState(null)
     
     useEffect(() => {
          set_std_image(props.studentImage)
          set_app_logo(props.appInfo.logo)
          set_home_banner(props.appInfo.home_banner)
          set_home_banner_link(props.appInfo.home_banner_link)
          const favicon = document.querySelector("#favicon")
          favicon.setAttribute("href",props.appInfo.fav)

          const title = document.querySelector("#title")
          title.innerHTML = props.appInfo.title
     })
     

     const history = useHistory();
     const log_out = () => {
          localStorage.removeItem("authenticated")
          
          history.push("/")
     }

     return(
     <section className="topbar" style={{
          padding : "0 0 15px 15px",
     }}>
          <div className="container">

               {/* banner ad start */}
               <div className="row">
                    <div className="col-md-12">
                        {
                            home_banner ? 
                            <a href={home_banner_link}>
                                 <img src={home_banner} style={{
                                   width : "100%"
                              }} className="img-fluid"></img>
                            </a> : 
                            "Banner Ad"
                        }
                    </div>
                </div>
                {/* banner ad end */}

               <div className="row" style={{
                    paddingTop : "15px",
               }}>

                    {/* logo part start */}
                    <div className="col-md-2 col-6 logo-part">
                         <Link to="/">
                              {
                                   app_logo ? 
                                   <img src={app_logo} 
                                   style={{
                                        marginTop : "10px",
                                   }}
                                   className="img-fluid"
                                   ></img> : 
                                   "Logo"
                              }
                         </Link>
                    </div>
                    {/* logo part end */}


                    {/* middle part start */}
                    <div className="col-md-9 nav-item for-pc">

                         {/* for pc start */}
                         <div className="for-pc-nav">
                              <ul>
                                   {
                                        props.menuList && Object.entries(props.menuList.data).map( (menu_list,index) => (
                                        <li>
                                             {
                                                  menu_list[1].link ? 
                                                  <a href={menu_list[1].link}
                                                  > 
                                                  <i dangerouslySetInnerHTML={{
                                                       __html : menu_list[1].icon
                                                  }}> 
                                                  </i>
                                                       {menu_list[1].name}
                                                  </a> :
                                                  <a href="#"> 
                                                  <i dangerouslySetInnerHTML={{
                                                       __html : menu_list[1].icon
                                                  }}> 
                                                  </i>
                                                       {menu_list[1].name}
                                                       <i class="fas fa-angle-down"></i>
                                                       {/* dropdown start */}
                                                       <div className="dropdown-list">
                                                            <ul>
                                                                 {
                                                                      menu_list[1].child && Object.entries(menu_list[1].child).map((child_list,key) => (
                                                                      <li>
                                                                           <a href={child_list[1].link}>
                                                                           <i dangerouslySetInnerHTML={{
                                                                                __html : child_list[1].icon
                                                                           }}> 
                                                                           </i>
                                                                                {child_list[1].name}
                                                                           </a>
                                                                      </li>
                                                                      ))
                                                                 }
                                                                 
                                                            </ul>
                                                       </div>
                                                       {/* dropdown end */}
                                                  </a>
                                             }
                                             
                                             
                                        </li>

                                        ))
                                   }
                                  
                              </ul>
                         </div>
                         {/* for pc end */}

                    </div>
                    {/* middle part end */}


                    {/* login part start */}
                    <div className="col-md-1 col-6 login-btn">
                         <ul>
                              <li>
                                   {
                                        std_image?
                                        <img src={std_image}></img>
                                        : 
                                        <img src="/images/profile/user.png"></img>
                                   }
                                   

                                   {/* dropdown start */}
                                   <div className="dropdown">
                                        <ul>
                                        <li>
                                             <Link to="/profile">
                                                  <i className="fas fa-tachometer-alt"></i>
                                                  Dashboard
                                             </Link>
                                        </li>
                                        <li>
                                             <button onClick={log_out}>
                                             <i className="fas fa-sign-out-alt"></i>
                                                  Logout
                                             </button>       
                                        
                                        </li>
                                        </ul>
                                   </div>
                                   {/* dropdown end */}

                              </li>
                         </ul>
                    </div>
                    {/* login part end */}

               </div>

               {/* only for mobile show */}
               <div className="row">
                    {/* middle part start */}
                    <div className="col-md-9 nav-item for-mob">
                         <div className="for-pc-nav">
                         <ul>
                                   {
                                        props.menuList && Object.entries(props.menuList.data).map( (menu_list,index) => (
                                        <li>
                                             {
                                                  menu_list[1].link ? 
                                                  <a href={menu_list[1].link}
                                                  > 
                                                  <i dangerouslySetInnerHTML={{
                                                       __html : menu_list[1].icon
                                                  }}> 
                                                  </i>
                                                       {menu_list[1].name}
                                                  </a> :
                                                  <a href="#"> 
                                                  <i dangerouslySetInnerHTML={{
                                                       __html : menu_list[1].icon
                                                  }}> 
                                                  </i>
                                                       {menu_list[1].name}
                                                       <i class="fas fa-angle-down"></i>
                                                       {/* dropdown start */}
                                                       <div className="dropdown-list">
                                                            <ul>
                                                                 {
                                                                      menu_list[1].child && Object.entries(menu_list[1].child).map((child_list,key) => (
                                                                      <li>
                                                                           <a href={child_list[1].link}>
                                                                           <i dangerouslySetInnerHTML={{
                                                                                __html : child_list[1].icon
                                                                           }}> 
                                                                           </i>
                                                                                {child_list[1].name}
                                                                           </a>
                                                                      </li>
                                                                      ))
                                                                 }
                                                                 
                                                            </ul>
                                                       </div>
                                                       {/* dropdown end */}
                                                  </a>
                                             }
                                             
                                             
                                        </li>

                                        ))
                                   }
                                  
                              </ul>
                         </div>
                    </div>
                    {/* middle part end */}
               </div>
               {/* only for mobile show */}

          </div>
     </section>
     );
}
export default TopBarLogin;