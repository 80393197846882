
import TopBarLogin from "../Includes/TopBarLogin"
import FooterComponent from "../Includes/FooterComponent"
import { Link, useHistory } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { getAppInfo } from "../../action";
import { getMenu } from "../../action";

import React, { Component } from 'react'
import Select from 'react-select'
import axios from "axios";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useDispatch, useSelector } from "react-redux";
const MySwal = withReactContent(Swal)

const EditProfileComponent = () => {

    const history = useHistory();

    //check if autheticated
    if( !localStorage.getItem("authenticated") ){
        history.push("/");
    }
    //check if autheticated

    window.scrollTo(0,0)

    
    const dispatch = useDispatch();
    const district_institute_url = `${window.url}/student_data`
    const menu_url = `${window.url}/menu`

    const [name,set_name] = useState('');

    const [district_label,set_district_label] = useState('');
    const [district_value,set_district_value] = useState('');

    const [institute_label,set_institute_label] = useState('');
    const [institute_value,set_institute_value] = useState('');

    const [email,set_email] = useState('');
    const [phone,set_phone] = useState('');
    const [image,set_image] = useState('');
    const [upload_image, set_upload_image] = useState(null)

    const [district_data,set_district_data] = useState(null)
    const [institute_data,set_institute_data] = useState(null)

    const [ errors, set_error ] = useState(null)

    //verify token
    const [student_info,set_student_info] = useState(null);
    const token = localStorage.getItem("authenticated")
    const verify_url = `${window.url}/verify/token/${token}`
    const api_information = `${window.url}/appinfo`

    useEffect(() => {
        const loader = document.querySelector('#loader')
        
        //verify start
        fetch(verify_url)
        .then(response => response.json())
        .then( data => {
            if( data.error ){
                loader.style.display = "none"
                localStorage.removeItem("authenticated")
                history.push("/")
            }       
            if( data.student ){
                set_student_info(data.student)
                set_name(data.student.name)

                if( data.student.district_id ){
                    set_district_label(data.student.district_id.name)
                    set_district_value(data.student.district_id.id)
                }
                

                if( data.student.institute_id ){
                    set_institute_label(data.student.institute_id.name)
                    set_institute_value(data.student.institute_id.id)
                }

                set_email(data.student.email)
                set_phone(data.student.phone)
                set_image(data.student.image)
                loader.style.display = "none"
            }
        })
        .catch( err => {
            console.log(err)
            loader.style.display = "none"
        })
        //verify end

        //get district, institute
        fetch(district_institute_url)
        .then( response => response.json() )
        .then( data => {
            set_district_data(data.district)
            set_institute_data(data.institute)
        })
        .catch( err => {
            
        })
        //get district, institute

        //app information get
        fetch(api_information)
        .then( response => response.json() )
        .then( res => {
            dispatch(getAppInfo(res.app_info))
            loader.style.display = "none"
        })
        .catch( err => {
            loader.style.display = "none"
        })
        //app information get

        //menu list get
        fetch(menu_url)
        .then( response => response.json() )
        .then( res => {
            dispatch(getMenu(res.menu))
            loader.style.display = "none"
        })
        .catch( err => {
            loader.style.display = "none"
        })
        //menu list get

    }, [])

    const app_information = useSelector( state => state.appInformation )
    const menu_list = useSelector( state => state.menuList )

    const preview = useRef(null);
    const filePreview = (e) => {
        const file = e.target.files[0]
        set_upload_image(e.target.files[0])
        // const preview = document.querySelector("#image_preview")
        preview.current.src = URL.createObjectURL(file)
    }

    

    

    //save profile
    const saveProfile = () => {
        set_error("")
        const loader = document.querySelector('#loader')
        loader.style.display = "block"

        const update_profile_url = `${window.url}/update/profile`
        const form_Data = new FormData();
        form_Data.append("name", name)
        form_Data.append("image", upload_image)
        form_Data.append("district", district_value)
        form_Data.append("institute", institute_value)
        form_Data.append("email", email)
        form_Data.append("phone", phone)
        form_Data.append("token", token)

        axios.post(update_profile_url,form_Data)
        .then( res => {
            loader.style.display = "none"
            if( res.data.success ){
                MySwal.fire({
                    title : "SUCCESS",
                    text : res.data.success,
                })
                history.push("/profile")
            }
        })
        .catch( err => {
            loader.style.display = "none"
            if( err.response.data.error ){
                localStorage.removeItem("authenticated")
                history.push("/")
            }
            if( err.response.data.errors ){
                const single_error = err.response.data.errors
                const distructured_error = {...single_error}
                set_error(distructured_error)
            }
        })
    }
    
    console.clear()
    return(
        <div className="id">
            <div className="loader" id="loader"></div>

            {/* topbar section start */}
            <TopBarLogin studentImage={
                student_info ? student_info.image : null
            }
            appInfo={app_information}
            menuList={menu_list}
            ></TopBarLogin>
            {/* topbar section end */}


            {/* main profile section start */}
            <section className="profile_dashboard">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2 dashboard_box">

                            {/* title row start */}
                            <div className="row">
                                <div className="col-md-12">
                                    <p>
                                        Edit Your Information
                                    </p>
                                </div>
                            </div>
                            {/* title row end */}

                            {/* edit image row start */}
                            <div className="row edit_profile_image">
                                <div className="col-md-12">
                                    <ul>
                                        <li>
                                            {
                                                image ? 
                                                <img src={image} ref={preview} className="img-fluid"></img>
                                                :
                                                <img src="/images/profile/user.png" ref={preview} className="img-fluid"></img>
                                            }
                                            
                                        </li>
                                        <li>
                                            <label>Use (100x100) image </label> <br />
                                            <input type="file" className="form-control-file"  onChange={filePreview}></input>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* edit image row end */}

                             {/* edit input field row start */}
                             <div className="row edit_profile_input">

                                {/* name  */}
                                <div className="col-md-12">
                                    <label className="sr-only" >Student Name</label>
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <i className="fas fa-id-badge"></i>
                                        </div>
                                        </div>
                                        <input type="text" className="form-control" 
                                        value={name}
                                        onChange={
                                            e => {
                                                set_name(e.target.value)
                                            }
                                        }
                                        ></input>
                                        {
                                            errors &&
                                            <small
                                            className="form_error"
                                            >
                                                {errors.name}
                                            </small>
                                        }
                                    </div>
                                </div>

                                {/* select district  */}
                                <div className="col-md-12">
                                    <div className="input-group mb-2">
                                    <label>Choose District ( {district_label} ) </label>
                                    <Select 
                                    options={district_data}
                                    onChange={
                                        e => {
                                            set_district_value(e.value)
                                        }
                                    }
                                    >
                                    </Select>
                                    </div>
                                </div>

                                {/* institute */}
                                <div className="col-md-12">
                                    <div className="input-group mb-2">
                                        <label>Choose Institute ( {institute_label} ) </label>
                                        <Select options={institute_data}
                                        onChange={
                                            e => {
                                                set_institute_value(e.value)
                                            }
                                        }
                                        />
                                    </div>
                                </div>

                                {/* email */}
                                <div className="col-md-12">
                                    <label className="sr-only">Student Email</label>
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <i className="fas fa-envelope"></i>
                                        </div>
                                        </div>
                                        <input type="text" className="form-control"  placeholder="Enter you email address"
                                        value={email}
                                        onChange={
                                            e => {
                                                set_email(e.target.value)
                                            }
                                        }
                                        ></input>
                                        {
                                            errors &&
                                            <small
                                            className="form_error"
                                            >
                                                {errors.email}
                                            </small>
                                        }
                                    </div>
                                </div>

                                {/* phone */}
                                <div className="col-md-12">
                                    <label className="sr-only">Student Phone</label>
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <i className="fas fa-phone"></i>
                                        </div>
                                        </div>
                                        <input type="text" className="form-control"  placeholder="Enter your phone"
                                        value={phone}
                                        onChange={
                                            e => {
                                                set_phone(e.target.value)
                                            }
                                        }
                                        ></input>
                                        {
                                            errors &&
                                            <small
                                            className="form_error"
                                            >
                                                {errors.phone}
                                            </small>
                                        }
                                    </div>
                                </div>

                                <div className="col-md-12 save_info">
                                    <ul>
                                        <li>
                                            <Link to="/profile">
                                                Cancel
                                            </Link>
                                        </li>
                                        <li>
                                            <button onClick={saveProfile}>
                                                Save
                                            </button>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            {/* edit input field row end */}

                        </div>
                    </div>
                </div>
            </section>
            {/* main profile section end */}


            {/* footer section start */}
            <FooterComponent></FooterComponent>
            {/* footer section end */}

        </div>
    );
}

export default EditProfileComponent;