import TopBarLogin from "../Includes/TopBarLogin"
import FooterComponent from "../Includes/FooterComponent"
import { getAppInfo } from "../../action";
import { getMenu } from "../../action";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
const MySwal = withReactContent(Swal)

const PasswordChange = () => {

     const history = useHistory();

     //check if autheticated
     if( !localStorage.getItem("authenticated") ){
          history.push("/");
     }
     //check if autheticated

     window.scrollTo(0,0)

     const dispatch = useDispatch();
     const [old_password, set_old_password] = useState("");
     const [password, set_password] = useState("");
     const [confirm_password, set_confirm_password ] = useState("")
     const [ errors, set_error ] = useState(null)
     const [student_info,set_student_info] = useState();
     const token = localStorage.getItem("authenticated")
    
     
     //urls
     const change_password_url = `${window.url}/changepassword`
     const verify_url = `${window.url}/verify/token/${token}`
     const api_information = `${window.url}/appinfo`
     const menu_url = `${window.url}/menu`

     useEffect(() => {
          const loader = document.querySelector('#loader')
          loader.style.display = "block"
          fetch(verify_url)
          .then(response => response.json())
          .then( data => {

               if( data.error ){
                    localStorage.removeItem("authenticated")
                    history.push("/")
                    loader.style.display = "none"
               }       
               
               if( data.student ){
                    set_student_info(data.student)
                    loader.style.display = "none"
               }
          })
          .catch( err => {
               console.log(err)
               loader.style.display = "none"
          })

          //app information get
          fetch(api_information)
          .then( response => response.json() )
          .then( res => {
               dispatch(getAppInfo(res.app_info))
               loader.style.display = "none"
          })
          .catch( err => {
               loader.style.display = "none"
          })
          //app information get

           //menu list get
          fetch(menu_url)
          .then( response => response.json() )
          .then( res => {
               dispatch(getMenu(res.menu))
               loader.style.display = "none"
          })
          .catch( err => {
               loader.style.display = "none"
          })
          //menu list get


     },[])

     const app_information = useSelector( state => state.appInformation )
     const menu_list = useSelector( state => state.menuList )

     const changePassword = () => {
          const loader = document.querySelector('#loader')
          loader.style.display = "block"
          set_error("")

          if( old_password && password && confirm_password ){
               const form_data = new FormData();
               form_data.append("old_password",old_password)
               form_data.append("password",password)
               form_data.append("password_confirmation",confirm_password)
               form_data.append("token",token)
               
               axios.post(change_password_url,form_data)
               .then( res => {
                    loader.style.display = "none"
                    if( res.data.error ){
                         MySwal.fire({
                              title : "ERROR",
                              text : res.data.error,
                         })
                    }
                    if( res.data.success ){
                         MySwal.fire({
                              title : "SUCCESS",
                              text : res.data.success,
                         })
                    }
               })
               .catch( err => {
                    loader.style.display = "none"

                    if( err.response.data.errors ){
                         const single_error = err.response.data.errors
                         const distructured_error = {...single_error}
                         set_error(distructured_error)
                    }
                
               })
          }
          else{
               loader.style.display = "none"
               MySwal.fire({
                    title : "WARNING",
                    text : "Please fill up the input fields",
               })
          }
     }

     console.clear()
     return(
          <div className="id">
               <div className="loader" id="loader"></div>
               {/* topbar section start */}
               <TopBarLogin studentImage={
                student_info ? student_info.image : null
            }
            appInfo={app_information}
            menuList={menu_list}
            ></TopBarLogin>
               {/* topbar section end */}

               {/* main profile section start */}
            <section className="profile_dashboard">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2 dashboard_box">

                            {/* title row start */}
                            <div className="row">
                                <div className="col-md-12">
                                    <p>
                                        Change Password
                                    </p>
                                </div>
                            </div>
                            {/* title row end */}


                             {/* edit input field row start */}
                             <div className="row edit_profile_input">

                                   {/* old password */}
                                   <div className="col-md-12">
                                        <div className="input-group mb-2">
                                             <div className="input-group-prepend">
                                             <div className="input-group-text">
                                             <i className="fas fa-key"></i>
                                             </div>
                                             </div>
                                             <input type="password" className="form-control" 
                                             placeholder="Old Password"
                                             onChange={
                                                  e => {
                                                       set_old_password(e.target.value)
                                                  }
                                             }
                                             >
                                             </input>
                                             {
                                                  errors &&
                                                  <small
                                                  className="form_error"
                                                  >
                                                  {errors.old_password}
                                                  </small>
                                             }
                                        </div>
                                   </div>

                                   {/* new password */}
                                   <div className="col-md-12">
                                        <div className="input-group mb-2">
                                             <div className="input-group-prepend">
                                             <div className="input-group-text">
                                             <i className="fas fa-key"></i>
                                             </div>
                                             </div>
                                             <input type="password" className="form-control" 
                                             placeholder="New Password"
                                             onChange={
                                                  e => {
                                                       set_password(e.target.value)
                                                  }
                                             }
                                             >
                                             </input>
                                             {
                                                  errors &&
                                                  <small
                                                  className="form_error"
                                                  >
                                                  {errors.password}
                                                  </small>
                                             }
                                        </div>
                                   </div>

                                   {/* confirm new password */}
                                   <div className="col-md-12">
                                        <div className="input-group mb-2">
                                             <div className="input-group-prepend">
                                             <div className="input-group-text">
                                             <i className="fas fa-key"></i>
                                             </div>
                                             </div>
                                             <input type="password" className="form-control" 
                                             placeholder="Confirm New Password"
                                             onChange={
                                                  e => {
                                                       set_confirm_password(e.target.value)
                                                  }
                                             }
                                             >
                                             </input>
                                        </div>
                                   </div>

                                   <div className="col-md-12 save_info">
                                        <ul>
                                             <li>
                                             <Link to="/profile">
                                                  Cancel
                                             </Link>
                                             </li>
                                             <li>
                                             <button onClick={changePassword}>
                                                  Change Password
                                             </button>
                                             </li>
                                        </ul>
                                   </div>

                            </div>
                            {/* edit input field row end */}

                        </div>
                    </div>
                </div>
            </section>
            {/* main profile section end */}

               {/* footer section start */}
               <FooterComponent></FooterComponent>
               {/* footer section end */}
          </div>
     );
}
export default PasswordChange;