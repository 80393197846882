
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const TopBarComponent = (props) => {

    const [app_logo, set_app_logo] = useState(null)
    const [home_banner, set_home_banner] = useState(null)
    useEffect(() => {
        set_app_logo(props.appInfo.logo)
        set_home_banner(props.appInfo.home_banner)
        const favicon = document.querySelector("#favicon")
        favicon.setAttribute("href",props.appInfo.fav)
        
        const title = document.querySelector("#title")
        title.innerHTML = props.appInfo.title
    })
    return(
        <section className="topbar">
            <div className="container">

                

                <div className="row">

                    {/* logo part start */}
                    <div className="col-md-2 col-3 logo-part">
                        <Link to="/">
                            {
                                app_logo ? 
                                <img src={app_logo} className="img-fluid"></img> : 
                                "Logo"
                            }
                        </Link>
                    </div>
                    {/* logo part end */}

                    {/* login part start */}
                    <div className="col-md-10 col-9 login-btn">
                        <Link to="/">
                        <i className="fas fa-sign-in-alt"></i>
                            Login
                        </Link>
                    </div>
                    {/* login part end */}

                </div>
            </div>
        </section>
    );
}

export default TopBarComponent;