
const FooterComponent = () => {
    return (
        <footer>
            <ul>
                <li>
                    Developed By <a href="https://www.zaman-it.com/" target="_blank">Zaman IT</a>
                </li>
            </ul>
        </footer>
    );
}
export default FooterComponent;