import axios from "axios"

import TopBarLogin from "../Includes/TopBarLogin"
import {getClassList} from "../../action"
import FooterComponent from "../Includes/FooterComponent"
import { Link, useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getAppInfo } from "../../action";
import { getMenu } from "../../action";

const ProfileComponent = () => {

    const history = useHistory();
    

    //check if autheticated
    if( !localStorage.getItem("authenticated") ){
        history.push("/");
    }
    //check if autheticated

    window.scrollTo(0,0)

    //initialize
    const dispatch = useDispatch();
    const [student_info,set_student_info] = useState();

    //verify token
    const token = localStorage.getItem("authenticated")
    const verify_url = `${window.url}/verify/token/${token}`
    
    //url
    const class_url = `${window.url}/class/${token}`;
    const api_information = `${window.url}/appinfo`
    const menu_url = `${window.url}/menu`

    useEffect(() => {
        const loader = document.querySelector('#loader')

        loader.style.display = "block"
        
        fetch(verify_url)
        .then(response => response.json())
        .then( data => {

            if( data.error ){
                localStorage.removeItem("authenticated")
                history.push("/")
                loader.style.display = "none"
            }       
            
            if( data.student ){
                set_student_info(data.student)
                loader.style.display = "none"
            }
        })
        .catch( err => {
            console.log(err)
            loader.style.display = "none"
        })

        //get class data
        axios.get(class_url)
        .then( res => {

            if( res.data.no_student_found ){
                localStorage.removeItem("authenticated")
                history.push("/")
            }

            dispatch(getClassList(res))
        })
        .catch( err => {

            console.log(err)
        })

        //app information get
        fetch(api_information)
        .then( response => response.json() )
        .then( res => {
            dispatch(getAppInfo(res.app_info))
            loader.style.display = "none"
        })
        .catch( err => {
            loader.style.display = "none"
        })
        //app information get


        //menu list get
        fetch(menu_url)
        .then( response => response.json() )
        .then( res => {
            dispatch(getMenu(res.menu))
            loader.style.display = "none"
        })
        .catch( err => {
            loader.style.display = "none"
        })
        //menu list get
        

    }, [])

    const app_information = useSelector( state => state.appInformation )

    const menu_list = useSelector( state => state.menuList )

    //set data
    const classList = useSelector( state => state.class )

    console.clear()
    return(
        <div className="id">

            <div className="loader" id="loader"></div>

            {/* topbar section start */}
            <TopBarLogin 
            studentImage={
                student_info ? student_info.image : null
            }
            appInfo={app_information}
            menuList={menu_list}
            ></TopBarLogin>
            {/* topbar section end */}


            {/* main profile section start */}
            <section className="profile_dashboard">
                <div className="container">
                    <div className="row">

                        <div className="col-md-8 offset-md-2 dashboard_box">
                            <div className="row">

                                {/* profile image part start */}
                                <div className="col-md-2 col-2">
                                    <div className="profile_pic">
                                        {
                                            student_info && student_info.image ?
                                            <img src={student_info.image} className="img-fluid"></img>
                                            :
                                            <img src="/images/profile/user.png" className="img-fluid"></img>
                                        }
                                        
                                    </div>
                                </div>
                                {/* profile image part end */}

                                {/* profile information part start */}
                                <div className="col-md-8 col-8">
                                    <div className="profile_information">
                                        {
                                            student_info ? <h2>
                                            <i className="fas fa-file-signature"></i> {student_info.name}
                                            </h2> : ""
                                        }
                                        
                                        {
                                            student_info ?
                                            <p>
                                                <i className="fas fa-id-badge"></i> {student_info.student_id}
                                            </p>
                                            : ""
                                        }
                                    </div>
                                </div>
                                {/* profile information part end */}

                                {/* profile information edit icon part start */}
                                <div className="col-md-2 col-2">
                                    <div className="profile_information_edit">
                                        <Link to="/edit/profile">
                                            <i className="fas fa-edit"></i>   
                                        </Link>
                                    </div>
                                    <div className="profile_information_edit">
                                        <Link to="/reset/password">
                                            <i className="fas fa-key"></i>  
                                        </Link>
                                    </div>
                                </div>
                                {/* profile information edit icon part end */}

                            </div>
                        </div>

                        {/* choose class here start */}
                        <div className="col-md-8 offset-md-2 choose_class">

                            {/* class item start */}
                            {
                                classList ? 
                                classList.map( item => (
                                <div className="class_item">
                                    <Link to={`/class/${item.id}`}>
                                        <ul>
                                            <li>
                                                <img src={item.icon} className="img-fluid"></img>
                                            </li>
                                            <li>
                                            { item.name }
                                            </li>
                                        </ul>
                                    </Link>
                                </div>
                                ))
                                : "Please Wait"
                            }
                            
                            {/* class item end */}


                        </div>
                        {/* choose class here end */}

                    </div>
                </div>
            </section>
            {/* main profile section end */}


            {/* footer section start */}
            <FooterComponent></FooterComponent>
            {/* footer section end */}

        </div>
    );
}
export default ProfileComponent;