import FooterComponent from "../Includes/FooterComponent"
import TopBarLogin from "../Includes/TopBarLogin"
import { useParams } from "react-router";

import { Link, useHistory } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getClassSubject } from "../../action";
import { useState } from "react";
import { getAppInfo } from "../../action";
import { getMenu } from "../../action";

const SubjectComponent = () => {
     
     const history = useHistory();
     //check if autheticated
     if( !localStorage.getItem("authenticated") ){
          history.push("/");
     }
     //check if autheticated

     window.scrollTo(0,0)

     const dispatch = useDispatch();
     const { id } =  useParams();

     const subject_url = `${window.url}/class/${id}/subject`;
     const api_information = `${window.url}/appinfo`
     const menu_url = `${window.url}/menu`

     
     //verify token
     const [student_info,set_student_info] = useState(null);
     const token = localStorage.getItem("authenticated")
     const verify_url = `${window.url}/verify/token/${token}`

     useEffect( () => {
          const loader = document.querySelector('#loader')

          fetch(verify_url)
          .then(response => response.json())
          .then( data => {
               if( data.error ){
                    localStorage.removeItem("authenticated")
                    history.push("/")
                    loader.style.display = "none"
               }       
               
               if( data.student ){
                    set_student_info(data.student)
                    loader.style.display = "none"
               }
          })
          .catch( err => {
               loader.style.display = "none"
               console.log(err)
          })

          //get class wise subject data
          axios.get(subject_url)
          .then( res => {
               if( res.data.error ){
                    history.push("/");
               }

               dispatch(getClassSubject(res));
          })
          .catch( err => {

               console.log(err)
          })

          //app information get
          fetch(api_information)
          .then( response => response.json() )
          .then( res => {
               dispatch(getAppInfo(res.app_info))
               loader.style.display = "none"
          })
          .catch( err => {
               loader.style.display = "none"
          })
          //app information get

          //menu list get
          fetch(menu_url)
          .then( response => response.json() )
          .then( res => {
               dispatch(getMenu(res.menu))
               loader.style.display = "none"
          })
          .catch( err => {
               loader.style.display = "none"
          })
          //menu list get


     },[subject_url])

     const subject = useSelector( state => state.classSubject.subject )
     const app_information = useSelector( state => state.appInformation )
     const menu_list = useSelector( state => state.menuList )

     // console.clear()
     return(
          <div className="id">

               <div className="loader" id="loader"></div>
               {/* topbar section start */}
               <TopBarLogin studentImage={
                student_info ? student_info.image : null
            }
            appInfo={app_information}
            menuList={menu_list}
            ></TopBarLogin>
               {/* topbar section end */}

               {/* main subject section start */}
               <section className="profile_dashboard">
                    <div className="container">

                         <div className="row page_indicator">
                              <div className="col-md-8 offset-md-2">
                                   <ul>
                                        <li>
                                             <Link to="/profile">
                                                  <i className="fas fa-angle-left"></i>
                                                  Back To The Class List                                             
                                             </Link>
                                        </li>
                                   </ul>
                              </div>
                         </div>

                         { subject ? Object.entries(subject).map ( group => ( 
                         <div className="row">

                              {/* title start */}
                              <div className="col-md-8 offset-md-2 choose_class">
                                   <h2>{group[1].name}</h2>

                              </div>
                              {/* title end */}

                              {/* choose subject here start */}
                              <div className="col-md-8 offset-md-2 choose_class">

                                   {/* subject item start */}
                                   { group[1].subject.data ? Object.entries(group[1].subject.data).map ( item => ( 
                                   

                                        <div className="class_item">
                                             <Link to={{
                                                  pathname : `/class/subject/${item[1].id}`,
                                                  state: {
                                                       className: item[1].class,
                                                  },
                                             }}>
                                                  <ul>
                                                  <li>
                                                       <img src={item[1].icon} className="img-fluid"></img>
                                                  </li>
                                                  <li>
                                                  {item[1].name}  
                                                  </li>
                                                  </ul>
                                             </Link>
                                        </div>

                                        )) : "Please Wait"
                                   }
                                   {/* subject item end */}


                              </div>
                              {/* choose subject here end */}

                         </div>
                         )) : "Please Wait"
                         }
                    </div>
               </section>
               {/* main subject section end */}

               {/* footer section start */}
               <FooterComponent></FooterComponent>
               {/* footer section end */}
          </div>
     );
}
export default SubjectComponent;