
import classListReducer from "./classList"
import { combineReducers } from 'redux'
import classSubjectReducer from "./classSubject";
import chapterLesson from "./chapterLesson";
import appInformation from "./appInformation";
import menuList from "./menuList";

const allReducer = combineReducers({
     class : classListReducer,
     classSubject : classSubjectReducer,
     chapterLesson : chapterLesson,
     appInformation : appInformation,
     menuList : menuList
})

export default allReducer;