import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import EditProfileComponent from "./Component/Pages/EditProfileComponent";
import HomeComponent from "./Component/Pages/HomeComponent";
import PasswordChange from "./Component/Pages/PasswordChangeComponent";
import ProfileComponent from "./Component/Pages/ProfileComponent";
import SubjectComponent from "./Component/Pages/SubjectComponent";
import VideoComponent from "./Component/Pages/VideoComponent";

function App() {
  return (

    <Router>
      <Switch>
        <div className="App">

          <Route exact path="/">
            <HomeComponent></HomeComponent>
          </Route>

          <Route exact path="/profile">
            <ProfileComponent></ProfileComponent>
          </Route>

          <Route exact path="/class/:id">
            <SubjectComponent></SubjectComponent>
          </Route>

          <Route exact path="/edit/profile">
            <EditProfileComponent></EditProfileComponent>
          </Route>

          

          <Route exact path="/class/subject/:id">
            <VideoComponent></VideoComponent>
          </Route>

          <Route exact path="/reset/password">
            <PasswordChange></PasswordChange>
          </Route>
        </div>
      </Switch>
      
    </Router>
    
  );
}

export default App;
