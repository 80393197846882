
import TopBarComponent from "../Includes/TopBarComponent"
import FooterComponent from "../Includes/FooterComponent"
import { Link, useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getAppInfo } from "../../action";
const MySwal = withReactContent(Swal)

const HomeComponent = () => {
    window.scrollTo(0,0)

    const history = useHistory();
    //check if autheticated
    if( localStorage.getItem("authenticated") ){
        history.push("/profile");
    }
    //check if autheticated


    //INITIALIZATION
    const dispatch = useDispatch();
    
    //urls
    const api_information = `${window.url}/appinfo`

    useEffect(() => {
        const loader = document.querySelector('#loader')
        
        //app information get
        fetch(api_information)
        .then( response => response.json() )
        .then( res => {
            dispatch(getAppInfo(res.app_info))
            loader.style.display = "none"
        })
        .catch( err => {
            loader.style.display = "none"
        })
        //app information get


    }, [])

    const app_information = useSelector( state => state.appInformation )

    const [student_id, set_student_id] = useState("");
    const [password, set_password] = useState("");
    const [ errors, set_error ] = useState(null)

    //do login
    const login_url = `${window.url}/student/login`
    

    const do_login = () => {
        const loader = document.querySelector('#loader')
        loader.style.display = "block"

        const form_data = new FormData();

        if( student_id && password ){
            form_data.append("student_id", student_id);
            form_data.append("password", password);
            axios.post(login_url,form_data)
            .then( res => {
                loader.style.display = "none"
                set_error("")

                if( res.data.error ){
                    MySwal.fire({
                        title : "ERROR",
                        text : res.data.error,
                    })
                }

                if( res.data.token ){
                    
                    if( localStorage.getItem("autheticated") ){
                        localStorage.removeItem("autheticated")
                        localStorage.setItem('authenticated',res.data.token)
                        history.push("/profile")
                    }
                    else{
                        localStorage.setItem('authenticated',res.data.token)
                        history.push("/profile")
                    }
                }

            })
            .catch( err => {
                loader.style.display = "none"
                const single_error = err.response.data.errors
                const distructured_error = {...single_error}
                set_error(distructured_error)
            })
        }
        else{
            loader.style.display = "none"
            MySwal.fire({
                title : "WARNING",
                text : "Please fill up the input field",
            })
        }
        
    }
    console.clear()
    return(
        <div className="id">
            <div className="loader" id="loader"></div>
            
            {/* topbar section start */}
            <TopBarComponent appInfo={app_information}></TopBarComponent>
            {/* topbar section end */}


            {/* login section start */}
            <section className="login">
                <div className="container">

                    {/* title part start */}
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="text-center">
                                {
                                    app_information ? app_information.home_title : "Loading..."
                                }
                            </h2>
                        </div>
                    </div>
                    {/* title part end */}

                    <div className="row">
                        <div className="col-md-4 offset-md-4 login-form">

                            {/* student id */}
                            <div className="col-auto">
                                <label className="sr-only" >Student ID</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <i className="fas fa-id-badge"></i>
                                    </div>
                                    </div>
                                    <input type="text" className="form-control" id="student_id" placeholder="Student ID"
                                    onChange={
                                        e => {
                                            set_student_id(e.target.value)
                                        }
                                    }
                                    ></input>
                                    {
                                        errors &&
                                        <small
                                        className="form_error"
                                        >
                                            {errors.student_id}
                                        </small>
                                    }
                                </div>
                            </div>

                            {/* password */}
                            <div className="col-auto">
                                <label className="sr-only">Password</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <i className="fas fa-key"></i>
                                        </div>
                                    </div>
                                    <input type="password" className="form-control" id="password" placeholder="Password"
                                    onChange={
                                        e => {
                                            set_password(e.target.value)
                                        }
                                    }
                                    ></input>
                                    {
                                        errors &&
                                        <small
                                        className="form_error"
                                        >
                                            {errors.password}
                                        </small>
                                    }
                                </div>
                            </div>

                            {/* login button */}
                            <div className="col-auto login-button">
                                <button onClick={do_login}>
                                    <i className="fas fa-sign-in-alt"></i>
                                    Login
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* login section end */}


            {/* footer section start */}
            <FooterComponent></FooterComponent>
            {/* footer section end */}

        </div>
    );
}
export default HomeComponent;