

import FooterComponent from "../Includes/FooterComponent"
import TopBarLogin from "../Includes/TopBarLogin"
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getChapterLesson } from "../../action";
import { useState } from "react";
import { getAppInfo } from "../../action";
import { getMenu } from "../../action";

const VideoComponent = () => {

    const history = useHistory();
    //check if autheticated
    if( !localStorage.getItem("authenticated") ){
        history.push("/");
    }
    //check if autheticated

    window.scrollTo(0,0)

    const dispatch = useDispatch();
    const {id} = useParams();
    const location = useLocation();
    const { className } = location.state
    const chapter_url = `${window.url}/class/subject/${id}`;
    const api_information = `${window.url}/appinfo`

    //verify token
    const [student_info,set_student_info] = useState(null);
    const token = localStorage.getItem("authenticated")
    const verify_url = `${window.url}/verify/token/${token}`
    const menu_url = `${window.url}/menu`

    
    useEffect(() => {
        const loader = document.querySelector('#loader')

        //verify token
        fetch(verify_url)
        .then(response => response.json())
        .then( data => {
            if( data.error ){
                localStorage.removeItem("authenticated")
                history.push("/")
                loader.style.display = "none"
           }       
           
           if( data.student ){
                set_student_info(data.student)
                loader.style.display = "none"
           }
        })
        .catch( err => {
             console.log(err)
             loader.style.display = "none"
        })

        //get chapter, lesson
        axios.get(chapter_url)
        .then( res => {
            
            if( res.data.error ){
                history.goBack()
            }
            dispatch(getChapterLesson(res.data))
            
        })
        .catch( err => {
            console.log(err)
        })

        //app information get
        fetch(api_information)
        .then( response => response.json() )
        .then( res => {
            dispatch(getAppInfo(res.app_info))
            loader.style.display = "none"
        })
        .catch( err => {
            loader.style.display = "none"
        })
        //app information get

         //menu list get
         fetch(menu_url)
         .then( response => response.json() )
         .then( res => {
             dispatch(getMenu(res.menu))
             loader.style.display = "none"
         })
         .catch( err => {
             loader.style.display = "none"
         })
         //menu list get


    }, [chapter_url])

    const chapterLesson = useSelector( state => state.chapterLesson)
    const app_information = useSelector( state => state.appInformation )
    const menu_list = useSelector( state => state.menuList )

    const show_chapter_video = (e) => {
        
        if(e.target.classList[0]){
            e.target.classList.remove("true")
            e.target.nextElementSibling.style.display = "none"
        }
        else{
            e.target.classList.add("true")
            e.target.nextElementSibling.style.display = "block"
        }
    }

    const openLesson = (e) => {

        const iframe = document.querySelector("#showVideo")
        iframe.setAttribute("src",e.target.dataset.lesson)
    }

    console.clear()
    return(
        <div className="id">
            <div className="loader" id="loader"></div>

            {/* topbar section start */}
            <TopBarLogin studentImage={
                student_info ? student_info.image : null
            }
            appInfo={app_information}
            menuList={menu_list}
            ></TopBarLogin>
            {/* topbar section end */}


            {/* main video section start */}
            <section className="video">
                <div className="container">

                    <div className="row">
                        
                        {/* left part video start */}
                        <div className="col-md-8 left_part_video">
                            {
                                chapterLesson.link ?
                                <iframe id="showVideo" allowFullScreen src={chapterLesson.link} ></iframe>
                                : "Please wait"
                            }
                            
                        </div>
                        {/* left part video end */}

                        {/* right part start */}
                        <div className="col-md-4 right_part">
                            
                            {/* top part start */}
                            <div className="row top_part">

                                {/* class name start */}
                                <div className="col-md-6 col-6 class_name">
                                    <p>
                                        {className}
                                    </p>                                    
                                </div>
                                {/* class name start */}

                                {/* choose subject start */}
                                <div className="col-md-6 col-6 choose_subject">
                                    <ul>
                                        <li>
                                            {
                                                chapterLesson.name ? chapterLesson.name : ""
                                            }
                                            <i className="fas fa-angle-down"></i>
                                        </li>

                                        {/* dropdown start */}
                                        <div className="dropdown">
                                            <ul>
                                                {
                                                    chapterLesson.all_subject ? chapterLesson.all_subject.data.map( subject => (
                                                    <li>
                                                        {
                                                            subject.id == id ?
                                                            <Link to={{
                                                                pathname : `/class/subject/${subject.id}`,
                                                                state: {
                                                                     className: subject.class,
                                                                },
                                                           }}
                                                                className="active">
                                                                {subject.name}
                                                            </Link> 
                                                            :
                                                            <Link to={{
                                                                pathname : `/class/subject/${subject.id}`,
                                                                state: {
                                                                     className: subject.class,
                                                                },
                                                           }}>
                                                                {subject.name} 
                                                            </Link>
                                                            
                                                        }
                                                    </li>
                                                    )) : "Please wait"
                                                }
                                            </ul>
                                        </div>
                                        {/* dropdown end */}

                                    </ul>                                    
                                </div>
                                {/* choose subject start */}

                            </div>
                            {/* top part end */}

                            {/* bottom part start */}
                            <div className="row bottom_part">

                                {/* chapter item start */}
                                {
                                    chapterLesson.single_subject ? Object.entries(chapterLesson.single_subject.chapter.data).map( ([index,chapter]) => (
                                    <div className="col-md-12 chapter_item">
                                        <p className="" onClick={show_chapter_video}>
                                            {chapter.name}
                                            <i className="fas fa-plus-square"></i>
                                        </p>

                                        {/* charpter videos start */}
                                        <div className="chapter_video">
                                            <ul>
                                                {
                                                    chapter.lesson  ? Object.entries(chapter.lesson).map ( ([index,lesson]) => (
                                                        <li 
                                                    className="lessone_name"
                                                    onClick={openLesson} 
                                                    data-lesson={lesson.link}
                                                    >
                                                        <i className="fas fa-play-circle"></i>
                                                        {lesson.name} 
                                                    </li>

                                                    )) :  ""
                                                }
                                                
                                            </ul>
                                        </div>
                                        {/* charpter videos end */}

                                    </div>
                                    )) : "Please wait"
                                }
                                
                                {/* chapter item end */}

                                

                            </div>
                            {/* bottom part end */}

                        </div>
                        {/* right part end */}

                    </div>
                </div>
            </section>
            {/* main video section end */}


            {/* footer section start */}
            <FooterComponent></FooterComponent>
            {/* footer section end */}

        </div>
    );
}
export default VideoComponent;