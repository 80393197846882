export const getClassList = (res) => {
     return{
          type: 'classList',
          response : res,
     }
}

export const getClassSubject = (res) => {
     return{
          type : 'classSubject',
          response : res,
     }
}

export const getChapterLesson = (res) => {
     return {
          type : 'chapterLesson',
          response : res,
     }
}

export const getAppInfo = (res) => {
     return{
          type : 'appInformation',
          response : res,
     }
}

export const getMenu = (res) => {
     return{
          type : 'menuList',
          response : res,
     }
}